<script>
import Cropper from "cropperjs";

export default {
    name: "ImageCropper",
    model: {
        prop: "show",
        event: "change",
    },
    props: {
        show: Boolean,
        image: String,
        imageList: {
            type: Array,
            default: () => [],
        },
        aspectRatio: {
            type: [String, Number],
            default() {
                return 1;
            }
        }
    },
    data() {
        return {
            cropperImageSrc: "",
            cropper: null,
            showCropperModal: false,
            base64Image: null,
            cropperDetail: {},
            spfzd: 1,
            czfzd: 1,
            cropperImageIdx: 0,
        }
    },
    watch: {
        show() {
            this.statusChange()
        },
        image() {
            this.cropperImageSrc = this.image
        },
        imageList() {
            if (this.imageList) {
                this.cropperImageSrc = this.imageList[0]
            }
        }
    },
    mounted() {
        this.showCropperModal = this.show
        this.cropperImageSrc = this.image || this.imageList[0]
        
        this.initCropper()
    },
    methods: {
        statusChange() {
            
            this.cropperImageSrc = this.image || this.imageList[0]
            this.showCropperModal = this.show
            
            if (this.showCropperModal) {
                setTimeout(() => {
                    this.initCropper()
                }, 100);
            }
        },
        rotateLeft() {
            if (!this.cropper) return;
            
            this.cropper.rotate(-90)
        },
        cancelImage() {
            
            this.closeModal()
            
            if (!this.cropper) return;
            
            setTimeout(() => {
                this.cropper.destroy()
                this.cropper = null
            }, 10)
        },
        croppedImage() {
            
            if (!this.cropper) return;
            
            let data = this.cropper.getCroppedCanvas({
                maxWidth:1500,
                maxHeight:1500,
            }).toDataURL();
            
            this.$emit('cropped', data)

            setTimeout(() => {

                this.cropper.destroy()

            }, 100)

            this.closeModal()
        },
        closeModal() {
            this.cropperImageSrc = null;
            
            this.$emit('change', false)
        },
        rotateRight() {
            if (!this.cropper) return;
            
            this.cropper.rotate(90)
        },
        initCropper() {
            
            if (this.cropper) this.cropper.destroy()
            
            if (!this.cropperImageSrc) return;
            
            setTimeout(() => {
                
                let el = document.getElementById('cropperImage');
                let preview = document.getElementById('cropperImagePreview')
                
                
                this.cropper = new Cropper(el, {
                    aspectRatio: this.aspectRatio,
                    preview,
                    dragMode: "move",
                    crop: (event) => {
                        this.cropperDetail = event.detail
                    },
                })
            }, 50)
        },
        fd() {
            if (!this.cropper) return;
            
            this.cropper.scale(
                this.cropperDetail.scaleX * 1.1,
                this.cropperDetail.scaleY * 1.1,
            )
        },
        sx() {
            if (!this.cropper) return;
            
            this.cropper.scale(
                this.cropperDetail.scaleX * 0.9,
                this.cropperDetail.scaleY * 0.9,
            )
        },
        imageChanged(idx) {
            this.cropperImageSrc = this.imageList[idx]
            console.log(this.cropperImageSrc);
            this.initCropper()
        },
        spfz() {
            if (!this.cropper) return;
            
            this.spfzd = this.spfzd === -1 ? 1 : -1;
            
            this.cropper.scale(this.spfzd, this.czfzd)
        },
        czfz() {
            if (!this.cropper) return;
            
            this.czfzd = this.czfzd === -1 ? 1 : -1;
            
            this.cropper.scale(this.spfzd, this.czfzd)
        },
    }
}
</script>

<template>
    <div>
        <a-modal width="875px" title="生活照剪裁" :maskClosable="false" v-model="showCropperModal" @cancel="cancelImage"
                 @ok="croppedImage">
            <div class="cropper-box">
                <div class="cropper-img-container">
                    <div class="cropper-img-left bg-gz" v-if="cropperImageSrc">
                        <img id="cropperImage" :src="cropperImageSrc" alt="">
                    </div>
                    <div>
                        <div id="cropperImagePreview"></div>
                        <div class="text-center"><small>（预览图）</small></div>
                    </div>
                </div>
                <a-button-group class="cropper-btns">
                    
                    <a-button @click="fd" type="primary">放大</a-button>
                    <a-button @click="sx" type="primary">缩小</a-button>
                    <a-button @click="spfz" type="primary">水平翻转</a-button>
                    <a-button @click="czfz" type="primary">垂直翻转</a-button>
                    <a-button @click="rotateLeft" type="primary">向左旋转</a-button>
                    <a-button @click="rotateRight" type="primary">向右旋转</a-button>
                
                </a-button-group>
                
                <div class="waite-images" v-if="imageList && imageList.length">
                    
                    <a-tabs @change="imageChanged">
                        <template v-for=" (image,ik) in imageList ">
                            <a-tab-pane class="bg-gz" :key="ik">
                                <div slot="tab" class="bg-gz waite-img-box">
                                    <img class="waite-img" :src="image" alt="">
                                </div>
                            </a-tab-pane>
                        </template>
                    </a-tabs>
                
                </div>
            </div>
        </a-modal>
    </div>
</template>

<style scoped lang="less">

.cropper-img-container {
    display: flex;
    flex-direction: row;
}

.cropper-img-left {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddddd;
}

#cropperImage {
    max-width: 600px;
    max-height: 500px;
}

#cropperImagePreview {
    width: 228px;
    height: 288px;
    overflow: hidden;
    background: white;
    border: 2px solid #dddddd;
}

.cropper-box {
    position: relative;
}

.cropper-btns {
    position: absolute;
    left: 0;
    top: 0;
    
    /deep/ button {
        background-color: #333333;
        border-color: #dddddd !important;
    }
}

.waite-images {
    border-top: 1px solid #e8e8e8;
    
    /deep/ .ant-tabs-tab {
        padding: 10px;
        margin-right: 10px;
        border: 1px solid #e8e8e8;
    }
}

.waite-img-box {
    
    
    img {
        max-width: 80px;
        max-height: 80px;
    }
}
</style>